import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../../components/Layout"

export default () => (
  <Layout>
    <Container>
      <h1 className="my-4 cl-identity">Obrigada!</h1>
      <p>
        Sua mensagem é muito importante para mim e eu vou lê-la com carinho. ❤
      </p>
    </Container>
  </Layout>
)
